import React from 'react';
import './Loading.css'; // Asegúrate de crear este archivo CSS

const Loading = () => {
    return (
        <div className="loading">
            <img src="/logodaromtransparente.png" alt="Logo de Darom SA cargando" />
        </div>
    );
};

export default Loading;
